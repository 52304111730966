import { Box, experimentalStyled as styled, Typography } from '@mui/material';

export const StyledWrapperBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '132px 0 180px',

  [theme.breakpoints.down('mobile')]: {
    marginTop: '172px',
    marginBottom: '200px',
  },
}));

export const StyledBackgroundImg = styled(Box)(({ theme }) => ({
  width: '60px',
  height: '60px',
  background: theme.palette.text.primary,
  borderRadius: '12px',
  position: 'absolute',
  top: '23px',
  left: '24px',

  [theme.breakpoints.down('tabletS')]: {
    right: '68px',
    left: 'auto',
  },
}));

export const StyledIntroductoryBlock = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gridGap: '24px',

  'div:nth-of-type(2) > img': {
    marginBottom: '4px',
  },

  [theme.breakpoints.down('tabletS')]: {
    justifyContent: 'center',
    width: '212px',
    textAlign: 'center',

    'div:nth-of-type(3) > h6': {
      padding: '0 15px',
    },
  },
}));

export const StyledBenefitBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  marginTop: '48px',
  width: '282px',

  [theme.breakpoints.down('tabletS')]: {
    marginTop: '28px',
    flexDirection: 'column',
  },
}));

export const StyledImg = styled('img')(() => ({
  width: '60px',
  height: '60px',
  'z-index': 1,
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  marginLeft: '14px',
  fontSize: '16px',
  lineHeight: '24px',

  [theme.breakpoints.down('tabletS')]: {
    margin: '22px 0 0',
    width: '212px',
    paddingTop: 0,
  },
}));
