import React from 'react';
import { useTranslation } from 'react-i18next';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';

import { StyledSpatialBlock } from 'src/components/Landing/styled';

import { StyledQuestionsBlock, StyledAccordion, StyledAccordionSummary, StyledAccordionDetails } from './styled';

const QuestionsBlock = () => {
  const { t } = useTranslation();
  const questionsBlockData = [
    {
      id: 'panel1',
      header: 'headerFirstBlock',
      details: 'detailsFirstBlock',
    },
    {
      id: 'panel2',
      header: 'headerSecondBlock',
      detailsList: [
        'detailsSecondBlock.informationControl',
        'detailsSecondBlock.minimumErrors',
        'detailsSecondBlock.automationOfWork',
        'detailsSecondBlock.quicklyImport',
        'detailsSecondBlock.content',
        'detailsSecondBlock.increaseSales',
        'detailsSecondBlock.growBusiness',
      ],
    },
    {
      id: 'panel3',
      header: 'headerThirdBlock',
      detailsList: [
        'detailsThirdBlock.dataManagement',
        'detailsThirdBlock.informationManagement',
        'detailsThirdBlock.content',
        'detailsThirdBlock.manageMediaAssets',
        'detailsThirdBlock.managingDirectories',
        'detailsThirdBlock.dataFormatting',
        'detailsThirdBlock.timeAndConversion',
      ],
    },
    {
      id: 'panel4',
      header: 'headerFourthBlock',
      details: 'detailsFourthBlock.mainText',
      detailsList: [
        'detailsFourthBlock.products',
        'detailsFourthBlock.saleOfGoods',
        'detailsFourthBlock.informationStorage',
        'detailsFourthBlock.accurateInformation',
        'detailsFourthBlock.manualProcesses',
        'detailsFourthBlock.businessGrowth',
      ],
    },
    {
      id: 'panel5',
      header: 'headerFifthBlock',
      details: 'detailsFifthBlock',
    },
  ];

  return (
    <StyledSpatialBlock id="faq">
      <StyledQuestionsBlock>
        <Typography variant="h2" pb="40px">
          {t('landingPage.questionsBlock.title')}
        </Typography>

        {questionsBlockData.map(({ id, header, details, detailsList }, idx) => (
          <StyledAccordion key={id} disableGutters>
            <StyledAccordionSummary id={id} expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h3" component="p" mr="18px">
                {`0${idx + 1}`}
              </Typography>
              <Typography variant="h3">{t(`landingPage.questionsBlock.${header}`)}</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              {details && <Typography mb="14px">{t(`landingPage.questionsBlock.${details}`)}</Typography>}
              <ul>
                {detailsList?.map((text, idx) => (
                  <li key={idx}>{t(`landingPage.questionsBlock.${text}`)}</li>
                ))}
              </ul>
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
      </StyledQuestionsBlock>
    </StyledSpatialBlock>
  );
};

export default QuestionsBlock;
