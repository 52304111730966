import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Typography } from '@mui/material';

import RegisterBlock from 'src/components/Forms/RegisterForm/RegisterBlock';
import { StyledContainerForm } from 'src/components/Landing/BottomPageBlock/styled';
import PimLeft from 'src/components/Landing/icons/pim-left.png';
import PimRight from 'src/components/Landing/icons/pim-right.png';
import { StyledSpatialBlock } from 'src/components/Landing/styled';
import { LINK_TO_SURVEY } from 'src/constants';

import {
  StyledGetForFreeBlock,
  StyledRegistrationBlock,
  StyledTextContent,
  StyledPimRightIcon,
  StyledPimLeftIcon,
  SurveyLink,
} from './styled';

const GetForFreeBlock = () => {
  const { t } = useTranslation();

  return (
    <StyledSpatialBlock id="access">
      <StyledGetForFreeBlock>
        <StyledPimLeftIcon>
          <img width="298px" height="364px" src={PimLeft} loading="lazy" alt="pimLeft" />
        </StyledPimLeftIcon>
        <StyledRegistrationBlock>
          <Typography variant="h2">{t('landingPage.getForFreeBlock.title')}</Typography>
          <StyledTextContent fontWeight="400">
            <Trans i18nKey="landingPage.getForFreeBlock.contentPart">
              Don&apos;t miss the chance to become an early adopter of Otomate and create your ideal PIM precisely for
              your business needs. Take part in our
              <SurveyLink href={LINK_TO_SURVEY} target="_blank" rel="noopener noreferrer">
                survey
              </SurveyLink>
              and get a free UNLIMITED version. Full-scale customer support included.
            </Trans>
          </StyledTextContent>
          <StyledContainerForm className="styled-form">
            <RegisterBlock />
          </StyledContainerForm>
        </StyledRegistrationBlock>
        <StyledPimRightIcon>
          <img width="290px" height="363px" src={PimRight} loading="lazy" alt="pimRight" />
        </StyledPimRightIcon>
      </StyledGetForFreeBlock>
    </StyledSpatialBlock>
  );
};

export default GetForFreeBlock;
