import { Box, Accordion, AccordionSummary, AccordionDetails, experimentalStyled as styled } from '@mui/material';

export const StyledQuestionsBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '156px',

  'h5:first-of-type': {
    marginBottom: '40px',
    textAlign: 'center',
  },

  [theme.breakpoints.down('laptop')]: {
    padding: '0 24px',
    marginBottom: '200px',
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '0 16px',

    '& .MuiAccordionSummary-root': {
      padding: 0,
      '&:first-of-type': {
        marginRight: 0,
      },
    },
  },
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  maxWidth: '1092px',
  width: '100%',
  boxShadow: 'none',
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,

  '&:last-child': {
    borderBottom: 'none',
  },

  '&:before': {
    content: 'none',
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  margin: '12px 0',
  padding: '0 30px 0 24px',

  'h2:first-of-type': {
    marginRight: '18px',
  },

  '& .MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },

  [theme.breakpoints.down('tabletM')]: {
    fontSize: '22px',
    lineHeight: '32px',
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: '20px',
    lineHeight: '28px',

    '&:first-of-type': {
      marginRight: '16px',
    },
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: '0 61px 24px 70px',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '24px',

  p: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
  },

  ul: {
    margin: 0,
    padding: 0,
  },

  li: {
    listStyleType: 'none',
  },

  'li:before': {
    content: '""',
    display: 'inline-block',
    height: '4px',
    width: '4px',
    verticalAlign: 'middle',
    borderRadius: '50%',
    backgroundColor: theme.palette.text.primary,
    margin: '0 8px',
  },

  [theme.breakpoints.down('tabletM')]: {
    textAlign: 'justify',
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '0 30px 16px 40px',
  },
}));

export const StyledWrapperBlock = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 140px 0 180px;
`;
