export const richMarkup = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: [
    {
      '@type': 'Question',
      name: "What's a PIM?",
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'PIM stands for Product Information Management. Otomate PIM SaaS platform provides a single source of truth for all product data and ensures the efficient management and distribution of product catalogs across online sales channels.',
      },
    },
    {
      '@type': 'Question',
      name: 'What are the benefits of using PIM?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Full control over your product information in one place. Minimize the number of errors. Automate manual and duplicate work. Import quickly and get to market faster than your competitors. Create rich product content optimized for all your sales channels. Increase sales through structured, appealing content. Grow your business with robust Otomate PIM.',
      },
    },
    {
      '@type': 'Question',
      name: 'What can you do with Otomate PIM?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Centralize and manage data without spreadsheets. Optimize and automate the management of product information. Enrich product content. Manage media assets. Manage catalogs and prepare them for sales channels. Format product data for a multichannel strategy. Reduce time-to-market and skyrocket conversion rate.',
      },
    },
    {
      '@type': 'Question',
      name: 'Who needs PIM?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'PIM platform can be perfect for businesses of all sizes. However, PIM becomes an indispensable tool when your company: Has a large number of products to operate. Sells products through multiple eCommerce channels; Stores product information in a few sources; Needs accurate product information; Is tired of too many manual processes; Is expanding or planning business growth.',
      },
    },
    {
      '@type': 'Question',
      name: 'How to become an early adopter and use Otomate for free?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'To be one of the first to experience all the features and functionality of Otomate PIM for free, you only need to complete the questionnaire and leave your email in the form.',
      },
    },
  ],
};
