import { graphql, PageProps } from 'gatsby';

import withGlobalStyles from '../components/GlobalStyleHOC';
import Landing from '../components/Landing';

export const query = graphql`
  query {
    api {
      getPlans {
        id
        name
        price
        paymentFrequency
      }
    }

    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withGlobalStyles<PageProps>(Landing);
