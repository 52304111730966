import { Box, styled, Typography } from '@mui/material';

interface CardProps {
  tooltip: boolean;
  active?: boolean;
}

interface StyledIconProps {
  imagewidth?: number;
}

//an error appears in the console if the property has capital letters
export const StyledIcon = styled('img')<StyledIconProps>(({ theme, imagewidth }) => ({
  width: imagewidth ? `${imagewidth}px` : '46px',
  marginRight: '18px',

  [theme.breakpoints.down('mobile')]: {
    width: '40px',
  },
}));

export const StyledBenefitsBlocks = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  maxWidth: '1200px',
  width: '100%',
  gridGap: '24px',

  'div:nth-last-of-type(-n + 3)': {
    background: theme.palette.success.contrastText,
  },

  [theme.breakpoints.down('desktop')]: {
    justifyContent: 'center',
  },

  [theme.breakpoints.down('tabletM')]: {
    gridGap: '22px',
  },
}));

export const StyledKeyFeaturesBlock = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '180px',

  [theme.breakpoints.down('laptop')]: {
    marginBottom: '200px',
  },
}));

export const StyledMobileWrapper = styled(Box)(() => ({
  width: '100%',
  maxWidth: '343px',
  margin: '0 16px',
  display: 'flex',
  gridGap: '16px',
  flexDirection: 'column',
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  maxWidth: '588px',
  paddingBottom: '64px',
  textAlign: 'center',

  [theme.breakpoints.down('tabletM')]: {
    fontSize: '36px',
    lineHeight: '44px',
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: '28px',
    lineHeight: '36px',
    paddingBottom: '40px',
    maxWidth: '343px',
  },
}));

export const StyledBenefitsContainer = styled(Box)<CardProps>(({ theme, tooltip, active }) => ({
  width: '384px',
  height: '102px',
  background: theme.palette.success[tooltip ? 'dark' : 'contrastText'],
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  padding: '26px 30px',
  boxShadow: tooltip && active ? '0 0 15px rgba(248, 144, 72, 0.35)' : 'none',
  cursor: tooltip && active ? 'pointer' : 'default',

  '&:hover': {
    boxShadow: tooltip ? '0 0 15px rgba(248, 144, 72, 0.35)' : 'none',
    cursor: tooltip ? 'pointer' : 'default',
  },

  [theme.breakpoints.down('laptop')]: {
    width: '350px',
  },

  [theme.breakpoints.down('mobile')]: {
    maxWidth: '343px',
    width: '100%',
  },
}));
