import { Box, Link, experimentalStyled as styled, Typography } from '@mui/material';

export const StyledGetForFreeBlock = styled(Box)(({ theme }) => ({
  marginBottom: '180px',
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.secondary.contrastText,
  position: 'relative',

  [theme.breakpoints.down('laptop')]: {
    marginBottom: '200px',
  },
}));

export const StyledRegistrationBlock = styled(Box)(({ theme }) => ({
  maxWidth: '838px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 auto',
  padding: '180px 0',

  [theme.breakpoints.down('laptop')]: {
    '& .styled-form': {
      padding: '0 25px',
    },
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '180px 16px 100px 16px',

    '& .styled-form': {
      padding: 0,
    },
  },
}));

export const StyledTextContent = styled(Typography)(({ theme }) => ({
  maxWidth: '646px',
  textAlign: 'center',
  padding: '0 24px 40px',
  fontSize: '16px',
  lineHeight: '24px',

  [theme.breakpoints.down('tabletM')]: {
    fontSize: '16px',
    maxWidth: '568px',
    width: '100%',
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '0 16px 32px',
    fontSize: '14px',
    maxWidth: '310px',
  },
}));

export const StyledPimRightIcon = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('desktop')]: {
    bottom: '42px',
  },

  [theme.breakpoints.down('laptop')]: {
    display: 'none',
  },
}));

export const StyledPimLeftIcon = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('laptop')]: {
    position: 'absolute',
    top: '-50px',

    '& img': {
      width: '179px',
      height: '219px',
    },
  },

  [theme.breakpoints.down('mobile')]: {
    top: '-40px',

    '& img': {
      width: '147px',
      height: '180px',
    },
  },
}));

export const SurveyLink = styled(Link)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.info.light,
  textDecoration: 'underline',
}));
