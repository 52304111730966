import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useTheme, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import { DocumentParts } from 'src/components/DocumentsPage/constants';
import { Routes } from 'src/types';

import { CheckboxBlock } from '../RegisterForm/styled';

import { CheckBoxIcon, CheckBoxCheckedIcon } from './CustomCheckboxIcons';
import { CheckboxTextConteiner } from './styled';

interface CustomCheckboxProps {
  name: string;
}

const CustomCheckbox = ({ name }: CustomCheckboxProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { control } = useFormContext();

  const fieldName = `${name}` as const;

  const followTheLink = (link: DocumentParts) => {
    window.open(`${Routes.Documents}${link}`);
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value } }) => (
        <CheckboxBlock>
          <Checkbox
            icon={<CheckBoxIcon theme={theme} />}
            checkedIcon={<CheckBoxCheckedIcon theme={theme} />}
            name="checkbox"
            onChange={onChange}
            checked={!!value}
          />
          <CheckboxTextConteiner display="flex" alignItems="center">
            <Typography component="span">
              {t('landingPage.registerBlock.checkbox')}
              <Typography onClick={() => followTheLink(DocumentParts.PrivacyPolicy)}>
                {t('footerText.privacyPolicy')}
              </Typography>
              {' & '}
              <Typography onClick={() => followTheLink(DocumentParts.TermsAndConditions)}>
                {t('contactUsPage.form.termsAndConditions')}
              </Typography>
            </Typography>
          </CheckboxTextConteiner>
        </CheckboxBlock>
      )}
    />
  );
};

export default CustomCheckbox;
