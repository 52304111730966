import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';

import Button from 'src/components/common/Button/Button';
import { DeviceSizes } from 'src/components/Landing/types';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { SEND_MAIL_BEFORE_ACCOUNT_INQUIRY } from 'src/queries';

import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import TextInput from '../TextInput';
import { registerFormSchema } from '../validationSchemas';

import { StyledRegisterBlock } from './styled';

interface SendUserDataProps {
  email: string;
  name: string;
  checkbox: boolean;
}

enum InputNames {
  name = 'name',
  email = 'email',
  checkbox = 'checkbox',
}

const RegisterBlock = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  const formProps = useForm<SendUserDataProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(registerFormSchema),
  });

  const {
    handleSubmit,
    watch,
    formState: { isValid },
  } = formProps;

  const [sendSupportEmail] = useLazyQuery(SEND_MAIL_BEFORE_ACCOUNT_INQUIRY, {
    fetchPolicy: 'network-only',
  });

  const handleSendUserData = async ({ email, name }: SendUserDataProps) => {
    if (isValid) {
      localStorage.setItem('_email', email);
      localStorage.setItem('_name', name);

      await sendSupportEmail({ variables: { data: { email, name } } }).finally(
        () => (window.location.href = '/confirmation'),
      );
    }
  };

  const { name, email, checkbox } = watch();

  const nonEmptyInputs = !!(name && email);

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(handleSendUserData)}>
        <Box position="relative">
          <StyledRegisterBlock>
            <TextInput name={InputNames.name} label="Name" />
            <TextInput name={InputNames.email} label="Email" />
            {windowWidth < DeviceSizes.mobileVerticalOrientitaion && (
              <Box display="flex" flexDirection="column" minHeight="44px" mb="24px">
                {nonEmptyInputs && <CustomCheckbox name={InputNames.checkbox} />}
              </Box>
            )}
            <Button disabled={!checkbox && nonEmptyInputs} type="submit">
              {t('landingPage.bottomPageBlock.freeForEarlyAdopters')}
            </Button>
          </StyledRegisterBlock>
          {nonEmptyInputs && windowWidth >= DeviceSizes.mobileVerticalOrientitaion && (
            <CustomCheckbox name={InputNames.checkbox} />
          )}
        </Box>
      </form>
    </FormProvider>
  );
};

export default RegisterBlock;
