import React, { useState } from 'react';

import { ClickAwayListener, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DeviceSizes, BenefitsContentProps } from 'src/components/Landing/types';
import { useWindowWidth } from 'src/hooks/useWindowWidth';

import { StyledBenefitsContainer, StyledIcon } from './styled';

interface BenefitCardProps {
  benefitsContent: BenefitsContentProps;
}

const tooltipWidth = ({ windowWidth }: { windowWidth: number }): string => {
  switch (true) {
    case windowWidth > DeviceSizes.laptopM:
      return '384px';
    case windowWidth > DeviceSizes.mobileLarge:
      return '350px';
    case windowWidth >= DeviceSizes.mobileMedium:
      return '311px';
    default:
      return '245px';
  }
};

const useStyles = makeStyles<Theme, { windowWidth: number }>((theme) => ({
  popper: {
    '&.MuiTooltip-popper': {
      zIndex: 2,
    },

    '&.MuiTooltip-popper[data-popper-placement*="bottom"]': {
      marginTop: '10px !important',
    },
  },
  tooltip: {
    maxWidth: tooltipWidth,
    background: theme.palette.common.white,
    borderRadius: '5px',

    '&>span.MuiTypography-root': {
      fontSize: '15px',
      lineHeight: '18px',
    },
  },
  arrow: {
    fontSize: '18px',
    marginLeft: '15px',
    '&:before': {
      backgroundColor: theme.palette.common.white,
      boxShadow: '0  1px 4px rgba(98, 98, 117, 0.25)',
    },
  },
  tooltipArrow: {
    background: theme.palette.common.white,
    marginLeft: '-30px',
    padding: '20px 20px',
    boxShadow: '0 2px 10px rgba(98, 98, 117, 0.25)',
  },
  styledText: {
    maxWidth: '256px',
    width: '100%',

    [theme.breakpoints.down('tabletM')]: {
      fontSize: '18px',
      lineHeight: '26px',
    },

    [theme.breakpoints.down('mobile')]: {
      fontSize: '16px',
      lineHeight: '26px',
    },
  },
}));

const BenefitsCard = ({ benefitsContent }: BenefitCardProps) => {
  const windowWidth = useWindowWidth();
  const classes = useStyles({ windowWidth });

  const { icon, tooltip, text } = benefitsContent;
  const [isOpenTooltip, setIsOpenTooltip] = useState<boolean>(false);

  const handleTooltipOpen = () => {
    setIsOpenTooltip(true);
  };

  const handleTooltipClose = () => {
    setIsOpenTooltip(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <StyledBenefitsContainer onClick={handleTooltipOpen} tooltip={!!tooltip} active={isOpenTooltip}>
        {tooltip ? (
          <>
            <Tooltip
              open={isOpenTooltip}
              onClose={handleTooltipClose}
              arrow
              placement="bottom-start"
              title={<Typography variant="caption">{tooltip}</Typography>}
              classes={{
                popper: classes.popper,
                tooltip: classes.tooltip,
                arrow: classes.arrow,
                tooltipArrow: classes.tooltipArrow,
              }}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <StyledIcon src={icon} alt="icon" loading="lazy" />
            </Tooltip>
            <Typography variant="h4" component="p" className={classes.styledText}>
              {text}
            </Typography>
          </>
        ) : (
          <>
            <StyledIcon src={icon} alt="icon" imagewidth={50} loading="lazy" />
            <Typography variant="h4" component="p" className={classes.styledText} color="text.disabled">
              {text}
            </Typography>
          </>
        )}
      </StyledBenefitsContainer>
    </ClickAwayListener>
  );
};

export default BenefitsCard;
