import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import AmazonLogoImg from 'src/components/Landing/icons/amazon.svg';
import EbayLogoImg from 'src/components/Landing/icons/ebay.svg';
import FacebookLogoImg from 'src/components/Landing/icons/facebook.svg';
import InstagramLogoImg from 'src/components/Landing/icons/instagram.svg';
import ShopifyLogoImg from 'src/components/Landing/icons/shopify.svg';
import IconSoon from 'src/components/Landing/icons/soon-icon.svg';
import WoocommerceLogoImg from 'src/components/Landing/icons/woocommerce.svg';
import { SLIDE_DELAY_LONG_TEXT, SLIDE_TRANSITION_SPEED, SPACE_BETWEEN_SLIDES } from 'src/constants';

import { useWindowWidth } from '../../../hooks/useWindowWidth';
import { DeviceSizes } from '../types';

import {
  StyledBackground,
  StyledIntegrationsContainer,
  StyledIconLogoWrapper,
  StyledWoocommerceBlock,
  StyledFacebookBlock,
  StyledInstagramBlock,
  StyledEbayBlock,
  StyledAmazonBlock,
  StyledSoonImg,
  StyledContent,
  StyledTextContent,
  StyledIntegrationWrapper,
  StyledShopifyBlock,
  StyledSwiperContainer,
} from './styled';

interface TextBlock {
  headline: string;
  paragraph: string;
  secondParagraph: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  slideWrapper: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  swiperPagination: {
    width: '100%',
    height: '25px',
    textAlign: 'center',
    transform: 'translate3d(0,0,0)',
    transition: '.3s opacity',
    marginTop: '30px',
  },
  swiperBullet: {
    margin: '0px 8px',
    display: 'inline-block',
    borderRadius: '50%',
    width: '10px',
    height: '10px',
    opacity: '1',
    background: theme.palette.common.white,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  swiperBulletActive: {
    background: theme.palette.info.light,
    width: '10px',
    height: '10px',
  },
  swiperSlide: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
}));

const Integrations = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();
  const classes = useStyles();

  const content: TextBlock[] = t('landingPage.integrations.textContent', { returnObjects: true });

  const contentBlocks = content.map(({ headline, paragraph }, idx) => (
    <Box key={idx}>
      <Typography variant="h3">{headline}</Typography>
      <Typography variant="subtitle1" component="p">
        {paragraph}
      </Typography>
    </Box>
  ));

  SwiperCore.use([Pagination, Autoplay]);

  return (
    <StyledIntegrationsContainer>
      <Typography variant="h2">{t('landingPage.integrations.title')}</Typography>
      <StyledBackground />
      <StyledContent>
        <StyledIntegrationWrapper>
          <StyledIconLogoWrapper>
            <StyledWoocommerceBlock>
              <img src={WoocommerceLogoImg} alt="woocommerce" />
            </StyledWoocommerceBlock>
            <StyledShopifyBlock>
              <img src={ShopifyLogoImg} alt="shopify" />
            </StyledShopifyBlock>
            <StyledFacebookBlock>
              <img src={FacebookLogoImg} alt="facebook" />
            </StyledFacebookBlock>
            <StyledEbayBlock>
              <img src={EbayLogoImg} alt="ebay" />
            </StyledEbayBlock>
            <StyledInstagramBlock>
              <img src={InstagramLogoImg} alt="instagram" />
              <StyledSoonImg src={IconSoon} alt="soonIcon" />
            </StyledInstagramBlock>
            <StyledAmazonBlock>
              <img src={AmazonLogoImg} alt="amazon" />
              <StyledSoonImg src={IconSoon} alt="soonIcon" />
            </StyledAmazonBlock>
          </StyledIconLogoWrapper>
        </StyledIntegrationWrapper>
        {windowWidth >= DeviceSizes.mobileVerticalOrientitaion ? (
          <Box display="flex">
            <StyledTextContent>{contentBlocks.slice(0, 2)}</StyledTextContent>
            <StyledTextContent>{contentBlocks.slice(2, 4)}</StyledTextContent>
          </Box>
        ) : (
          <StyledSwiperContainer>
            <Swiper
              observer
              observeParents
              slidesPerView="auto"
              speed={SLIDE_TRANSITION_SPEED}
              autoplay={{
                delay: SLIDE_DELAY_LONG_TEXT,
              }}
              pagination={{
                clickable: true,
                clickableClass: classes.swiperPagination,
                bulletClass: classes.swiperBullet,
                bulletActiveClass: classes.swiperBulletActive,
              }}
              spaceBetween={SPACE_BETWEEN_SLIDES}
              loop
              className={classes.slideWrapper}
            >
              {content.map(({ headline, paragraph }, idx) => (
                <SwiperSlide key={idx} className={classes.swiperSlide}>
                  <Typography variant="h3">{headline}</Typography>
                  <Typography variant="subtitle1" component="p">
                    {paragraph}
                  </Typography>
                </SwiperSlide>
              ))}
            </Swiper>
          </StyledSwiperContainer>
        )}
      </StyledContent>
    </StyledIntegrationsContainer>
  );
};

export default Integrations;
