import React from 'react';

import { Fab, styled } from '@mui/material';

import Iconography from '../Iconography';

const StyledFab = styled(Fab)(({ theme }) => ({
  display: 'none',
  position: 'fixed',
  bottom: 10,
  right: 10,
  width: 60,
  height: 60,
  background: theme.palette.info.light,
  boxShadow: 'none',
  zIndex: theme.zIndex.tooltip,

  '&:active': {
    boxShadow: 'none',
  },

  '& #gist-arrow-icon': {
    display: 'none',
  },

  '&.show': {
    display: 'inline-flex',
  },

  '&.top': {
    bottom: 80,
  },

  '&.open': {
    '& #gist-arrow-icon': {
      display: 'inline-block',
    },

    '& #gist-chat-icon': {
      display: 'none',
    },
  },

  '& .MuiSvgIcon-root#gist-chat-icon': {
    width: 28,
    height: 28,
  },

  '& .MuiSvgIcon-root#gist-arrow-icon': {
    marginTop: 3,
    width: 30,
    height: 30,
  },

  '&:hover': {
    background: theme.palette.info.light,
  },
}));

const GistButton = () => {
  return (
    <StyledFab id="gist-button" disableRipple>
      <Iconography id="gist-arrow-icon" iconName="expand-chevron" htmlColor="white" />

      <Iconography id="gist-chat-icon" iconName="chat-icon" />
    </StyledFab>
  );
};

export default GistButton;
