import { Box, experimentalStyled as styled } from '@mui/material';

export const CheckboxTextConteiner = styled(Box)(({ theme }) => ({
  '& .MuiTypography-root': {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontFamily: 'Roboto',
    lineHeight: '22px',
    fontSize: '14px',
  },

  span: {
    width: 'auto',
    height: 'auto',

    '&>.MuiTypography-root': {
      textDecoration: 'underline',
      display: 'inline',
      cursor: 'pointer',
    },
  },
}));
