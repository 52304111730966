import { Box, experimentalStyled as styled } from '@mui/material';

export const StyledBackground = styled(Box)(({ theme }) => ({
  width: '648px',
  height: '648px',
  background: theme.palette.primary.contrastText,
  filter: 'blur(115px)',
  position: 'absolute',

  [theme.breakpoints.down('tabletM')]: {
    width: '100%',
    bottom: 0,
    zIndex: 10,
    pointerEvents: 'none',
  },
}));

export const StyledIntegrationsContainer = styled(Box)(({ theme }) => ({
  maxWidth: '1400px',
  width: '100%',
  margin: '0 auto 180px',
  position: 'relative',

  [theme.breakpoints.down('laptop')]: {
    marginBottom: '200px',
  },

  [theme.breakpoints.down('tabletM')]: {
    marginBottom: '140px',
  },
}));

export const StyledIconLogoWrapper = styled(Box)(({ theme }) => ({
  margin: '43px 48px 0 0',
  maxWidth: '345px',
  width: '100%',
  'z-index': 1,
  position: 'relative',

  [theme.breakpoints.down('tabletM')]: {
    maxWidth: '370px',
    margin: '87px 0 0',
  },
}));

export const StyledWoocommerceBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '0 38px 70px 0',

  [theme.breakpoints.down('mobile')]: {
    margin: '0 0 48px',
    justifyContent: 'center',

    img: {
      maxWidth: '168px',
      width: '100%',
    },
  },
}));

export const StyledShopifyBlock = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('mobile')]: {
    display: 'flex',
    justifyContent: 'center',

    img: {
      maxWidth: '121px',
      width: '100%',
    },
  },
}));

export const StyledFacebookBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '50px 24px 56px 0',

  [theme.breakpoints.down('mobile')]: {
    margin: '52px 0 53px',
    justifyContent: 'center',

    img: {
      maxWidth: '168px',
      width: '100%',
    },
  },
}));

export const StyledInstagramBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: '56px',

  [theme.breakpoints.down('mobile')]: {
    margin: '0 0 48px',
    justifyContent: 'center',

    'img:first-of-type': {
      maxWidth: '133px',
      width: '100%',
    },
  },
}));

export const StyledEbayBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  margin: '0 0 26px 11px',

  [theme.breakpoints.down('mobile')]: {
    margin: '0 0 48px',
    justifyContent: 'center',

    img: {
      maxWidth: '95px',
      width: '100%',
    },
  },
}));

export const StyledAmazonBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  marginLeft: '14px',

  [theme.breakpoints.down('mobile')]: {
    margin: 0,
    justifyContent: 'center',

    'img:first-of-type': {
      maxWidth: '125px',
      width: '100%',
    },
  },
}));

export const StyledSoonImg = styled('img')`
  width: 50px;
  height: 34px;
  margin-left: 12px;
`;

export const StyledContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '0 24px',

  h3: {
    paddingBottom: '24px',
  },

  [theme.breakpoints.down('tabletM')]: {
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 24px 60px',
  },

  [theme.breakpoints.down('tabletS')]: {
    p: {
      padding: '0 16px',
    },
  },
}));

export const StyledTextContent = styled(Box)(({ theme }) => ({
  maxWidth: '385px',
  width: '100%',

  '&:nth-of-type(1)': {
    marginRight: '24px',
    'z-index': 1,
  },

  '&:nth-of-type(2)': {
    marginTop: '129px',
  },

  'div:nth-of-type(odd)': {
    paddingBottom: '80px',
  },

  [theme.breakpoints.down('tabletM')]: {
    maxWidth: '474px',

    '&:nth-of-type(1)': {
      marginRight: '19px',
    },

    '&:nth-of-type(2)': {
      marginBottom: '43px',
    },
  },

  [theme.breakpoints.down('tabletS')]: {
    display: 'none',
  },
}));

export const StyledIntegrationWrapper = styled(Box)(({ theme }) => ({
  marginRight: '24px',
  maxWidth: '346px',
  width: '100%',

  [theme.breakpoints.down('tabletM')]: {
    order: 3,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginRight: 0,
  },
}));

export const StyledSwiperContainer = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('tabletS')]: {
    display: 'flex',
    minHeight: '330px',
    maxWidth: '425px',
    width: '100%',
  },
}));
