import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import Button from 'src/components/common/Button/Button';
import Iconography from 'src/components/common/Iconography';
import RegisterBlock from 'src/components/Forms/RegisterForm/RegisterBlock';
import {
  StyledCloseBurgerMenu,
  StyledHeaderContainer,
  StyledHederBlock,
  StyledHederPosition,
  StyledOpenBurgerMenuWrapper,
  StyledWrapperHeader,
  HeaderButtonContainer,
  LogInButton,
} from 'src/components/Header/styled';
import MainScreanIcon from 'src/components/Landing/icons/main-landing-screen.png';
import { handleClickOnLoginButton, handleScrollToTop, Section } from 'src/constants';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import logoImage from 'src/images/logo.svg';

import { DeviceSizes } from '../types';

import {
  HeaderContentWrapper,
  StyledBackgroundGradient,
  StyledFab,
  StyledForm,
  StyledHeadLine,
  StyledLandingHeaderTab,
  StyledLandingHeaderTextBlock,
  StyledLandingHederContent,
  StyledTypography,
  StyledWrapperHeaderTab,
  StyledLogo,
  StyledMainScreanImage,
  StyledBackdrop,
  MainHeaderContainer,
  TitleContainer,
} from './styled';

const LandingHeader = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  const [showHeader, setShowHeader] = useState<boolean>(false);
  const [headerContentBlockHeight, setHeaderContentBlockHeight] = useState<number>();
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<Section>(Section.Top);

  const BACKGROUND_HEADER_CONTENT_HEIGHT = headerContentBlockHeight as number;

  const handleShowFixedHeader = useCallback(() => {
    setShowHeader(window.scrollY >= BACKGROUND_HEADER_CONTENT_HEIGHT);

    const header = document.getElementById('header') as HTMLElement;
    const headerOffsetHeight = header.offsetHeight;
    const permissibleScrollHeight = BACKGROUND_HEADER_CONTENT_HEIGHT + headerOffsetHeight;

    if (showHeader) {
      if (window.scrollY >= BACKGROUND_HEADER_CONTENT_HEIGHT && window.scrollY <= permissibleScrollHeight) {
        header.style.top = `-${permissibleScrollHeight - window.scrollY}px`;
      }
      if (window.scrollY >= permissibleScrollHeight) {
        header.style.top = '0px';
      }
    }
  }, [showHeader, BACKGROUND_HEADER_CONTENT_HEIGHT]);

  const handleBurgerMenu = () => {
    setIsBurgerMenuOpen((prev) => !prev);
  };

  const handleSectionChange = (section: Section) => {
    const el = document.getElementById(section);

    if (el) {
      const yOffset = el.offsetTop;

      window.scrollTo({ top: yOffset, behavior: 'smooth' });

      window.onscroll = () => {
        if (yOffset === window.scrollY) {
          setActiveSection(section);
          window.onscroll = null;
        }
      };
    }

    if (isBurgerMenuOpen) {
      handleBurgerMenu();
    }
  };

  const handleScrollBlock = useCallback(() => {
    const element = document.getElementById(activeSection);

    if (element) {
      const position = window.scrollY;

      const elementPosition = {
        top: element.offsetTop,
        bottom: element.offsetTop + element?.offsetHeight,
      };

      const windowPositionBottom = document.documentElement.clientHeight + position;

      const blockWasScrolledDown =
        windowPositionBottom - elementPosition.bottom > document.documentElement.clientHeight;

      const blockWasScrolledUp = elementPosition.top - document.documentElement.clientHeight > position;

      if (blockWasScrolledDown || blockWasScrolledUp) {
        setActiveSection(Section.Top);
      }
    }
  }, [activeSection]);

  useEffect(() => {
    const headerContentHeight = document.getElementById('header-content');

    setHeaderContentBlockHeight(headerContentHeight?.offsetHeight);

    document.addEventListener('scroll', handleShowFixedHeader);

    return () => document.removeEventListener('scroll', handleShowFixedHeader);
  }, [handleShowFixedHeader]);

  useEffect(() => {
    if (activeSection !== Section.Top) {
      window.addEventListener('scroll', handleScrollBlock);

      return () => {
        window.removeEventListener('scroll', handleScrollBlock);
      };
    }
  }, [activeSection, handleScrollBlock]);

  useEffect(() => {
    if (windowWidth >= DeviceSizes.laptopM && isBurgerMenuOpen) {
      setIsBurgerMenuOpen(false);
    }
  }, [windowWidth, isBurgerMenuOpen]);

  const handleSelectedSectionAbout = () => handleSectionChange(Section.About);
  const handleSelectedSectionAccess = () => handleSectionChange(Section.Access);
  const handleSelectedSectionFaq = () => handleSectionChange(Section.Faq);

  const headerButtons = () => (
    <>
      <HeaderButtonContainer>
        <LogInButton onClick={handleClickOnLoginButton} variant="contained">
          {t('landingPage.header.logInButton')}
        </LogInButton>

        <Button onClick={handleSelectedSectionAccess} fullWidth variant="contained">
          {t('landingPage.header.freeAccessButton')}
        </Button>
      </HeaderButtonContainer>
    </>
  );

  const contentHeaderPanel = () => (
    <>
      <StyledWrapperHeaderTab isBurgerMenuOpen={isBurgerMenuOpen}>
        <StyledLandingHeaderTab isActive={activeSection === Section.About} onClick={handleSelectedSectionAbout}>
          {t('landingPage.header.anchorMenu.about')}
        </StyledLandingHeaderTab>
        <StyledLandingHeaderTab isActive={activeSection === Section.Access} onClick={handleSelectedSectionAccess}>
          {t('landingPage.header.anchorMenu.access')}
        </StyledLandingHeaderTab>
        <StyledLandingHeaderTab isActive={activeSection === Section.Faq} onClick={handleSelectedSectionFaq}>
          {t('landingPage.header.anchorMenu.faq')}
        </StyledLandingHeaderTab>
      </StyledWrapperHeaderTab>
      <StyledCloseBurgerMenu onClick={handleBurgerMenu} isBurgerMenuOpen={isBurgerMenuOpen}>
        <Iconography iconName={isBurgerMenuOpen ? 'cancel' : 'burger-menu'} htmlColor="text.primary" />
      </StyledCloseBurgerMenu>
    </>
  );

  return (
    <Box>
      <Box height="82px">
        <StyledHederBlock id="header" sticky={showHeader}>
          <StyledWrapperHeader>
            <StyledHederPosition>
              <StyledLogo onClick={handleScrollToTop}>
                <img src={logoImage} alt="logo" />
              </StyledLogo>
              {contentHeaderPanel()}
              {!isBurgerMenuOpen && windowWidth >= DeviceSizes.laptopM && headerButtons()}
            </StyledHederPosition>
          </StyledWrapperHeader>
        </StyledHederBlock>
      </Box>

      <StyledHeaderContainer id="header-content">
        <StyledLandingHederContent>
          <StyledLandingHeaderTextBlock>
            <StyledBackgroundGradient />
            <HeaderContentWrapper>
              <MainHeaderContainer>
                <TitleContainer>
                  <StyledHeadLine variant="h1">
                    <Typography component="span">{t('landingPage.header.headerContent.firstHeadline')}</Typography>
                    {t('landingPage.header.headerContent.secondHeadline')}
                  </StyledHeadLine>
                  <StyledTypography variant="body2" fontWeight="400" maxWidth="516px">
                    {t('landingPage.header.headerContent.thirdHeadline')}
                  </StyledTypography>
                  <StyledForm>
                    <RegisterBlock />
                  </StyledForm>
                </TitleContainer>
                {windowWidth >= DeviceSizes.laptopM && (
                  <StyledMainScreanImage>
                    <img width="557px" height="486px" src={MainScreanIcon} alt="mainScrean" />
                  </StyledMainScreanImage>
                )}
              </MainHeaderContainer>
            </HeaderContentWrapper>
          </StyledLandingHeaderTextBlock>
        </StyledLandingHederContent>
      </StyledHeaderContainer>

      {isBurgerMenuOpen && (
        <>
          <StyledBackdrop />
          <StyledOpenBurgerMenuWrapper>
            {contentHeaderPanel()}
            {headerButtons()}
          </StyledOpenBurgerMenuWrapper>
        </>
      )}

      {showHeader && (
        <StyledFab onClick={handleScrollToTop} id="scroll-to-top-button">
          <Iconography iconName="chevron-arrow-right" fontSize="large" />
        </StyledFab>
      )}
    </Box>
  );
};

export default LandingHeader;
