import React from 'react';
import { useTranslation } from 'react-i18next';

import Circle from './circle.png';
import Diamond from './diamond.png';
import Laptop from './laptop.png';
import Phone from './phone.png';
import { StyledWrapperBlock, StyledIntroductoryBlock, StyledBenefitBlock, StyledImg, StyledText } from './styled';

const IntroductoryBlock = () => {
  const { t } = useTranslation();

  const introductoryBlockContent = [
    {
      id: 0,
      img: Diamond,
      altText: 'sphere-icon',
      content: t('landingPage.introductoryBlock.spreadsheets'),
    },
    {
      id: 1,
      img: Phone,
      altText: 'neocube-icon',
      content: t('landingPage.introductoryBlock.quickMarketEntry'),
    },
    {
      id: 2,
      img: Laptop,
      altText: 'abstract-sphere-icon',
      content: t('landingPage.introductoryBlock.increaseProductEfficiency'),
    },
    {
      id: 3,
      img: Circle,
      altText: 'cube-icon',
      content: t('landingPage.introductoryBlock.tool'),
    },
  ];

  return (
    <StyledWrapperBlock>
      <StyledIntroductoryBlock>
        {introductoryBlockContent?.map(({ img, content, id, altText }) => (
          <StyledBenefitBlock key={id}>
            <StyledImg src={img} alt={altText} />
            <StyledText variant="body2" fontWeight="500">
              {content}
            </StyledText>
          </StyledBenefitBlock>
        ))}
      </StyledIntroductoryBlock>
    </StyledWrapperBlock>
  );
};

export default IntroductoryBlock;
