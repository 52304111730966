import React from 'react';

import GistButton from '../common/Button/GistButton';
import Seo from '../SEO/SEO';
import { richMarkup } from '../SEO/seoData';

import AboutOtamateBlock from './AboutOtamateBlock/AboutOtamateBlock';
import BottomPageBlock from './BottomPageBlock/BottomPageBlock';
import Footer from './Footer/Footer';
import GetForFreeBlock from './GetForFreeBlock/GetForFreeBlock';
import Integrations from './Integrations/Integrations';
import IntroductoryBlock from './IntroductoryBlock/IntroductoryBlock';
import KeyFeaturesBlock from './KeyFeaturesBlock/KeyFeaturesBlock';
import LandingHeader from './LandingHeader/LandingHeader';
import QuestionsBlock from './QuestionsBlock/QuestionsBlock';
import { StyledLanding } from './styled';

function Landing() {
  const script = { content: JSON.stringify(richMarkup) };

  return (
    <>
      <Seo
        title="Efficient PIM for Multichannel Selling"
        description="Otomate is a one-stop solution for efficient Product Information Management. Facilitate growth with our multichannel platform designed for online sellers."
        script={script.content}
      />
      <StyledLanding>
        <LandingHeader />
        <IntroductoryBlock />
        <AboutOtamateBlock />
        <Integrations />
        <KeyFeaturesBlock />
        <GetForFreeBlock />
        <QuestionsBlock />
        <BottomPageBlock />
        <Footer />
        <GistButton />
      </StyledLanding>
    </>
  );
}

export default Landing;
