import { Box, experimentalStyled as styled } from '@mui/material';

export const StyledRegisterBlock = styled(Box)(({ theme }) => ({
  display: 'flex',

  '& .MuiFormControl-root': {
    paddingRight: '24px',
    maxWidth: '306px',
  },

  '& .MuiButtonBase-root': {
    maxWidth: '226px',
  },

  [theme.breakpoints.down('tabletM')]: {
    '& .MuiFormControl-root': {
      paddingRight: '20px',
    },
  },

  [theme.breakpoints.down('tabletS')]: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',

    '& .MuiFormControl-root': {
      margin: '0 0 26px 0',
      maxWidth: 'none',
      paddingRight: 0,
    },

    '& .MuiButtonBase-root': {
      width: '100%',
      paddingTop: '14px',
      maxWidth: '100%',
    },

    '>div:nth-of-type(2)': {
      marginBottom: '10px',
    },
  },
}));

export const CheckboxBlock = styled(Box)(({ theme }) => ({
  marginTop: '11px',
  display: 'flex',
  alignItems: 'flex-start',
  position: 'absolute',

  span: {
    width: '20px',
    height: '20px',
  },

  svg: {
    fontSize: '1.25rem',
    fill: 'none',
  },

  [theme.breakpoints.down('tabletS')]: {
    position: 'static',
    marginTop: 0,

    '&>div': {
      alignSelf: 'flex-start',
    },

    '& span,.MuiCheckbox-root': {
      width: 'auto',
      padding: 0,
      height: 'auto',
    },
  },
}));
