import { Box, experimentalStyled as styled } from '@mui/material';

export const StyledBottomPageBlock = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '180px',
  background: theme.palette.secondary.contrastText,

  [theme.breakpoints.down('desktop')]: {
    padding: '0 24px',
  },

  [theme.breakpoints.down('laptop')]: {
    marginBottom: '200px',
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '0 16px',
  },
}));

export const BottomPageContainer = styled(Box)(({ theme }) => ({
  maxWidth: '1124px',
  width: '100%',
  position: 'relative',
  padding: '180px 0',

  [theme.breakpoints.down('mobile')]: {
    padding: '100px 0 0 0',
  },
}));

export const BottomPageImageWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-60px',
  right: '-85px',

  [theme.breakpoints.down('desktop')]: {
    right: 0,
    top: '-30px',

    img: {
      width: '422px',
      height: '298px',
    },
  },

  [theme.breakpoints.down('laptop')]: {
    backgroundSize: 'contain',
  },
}));

export const BottomPageContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingLeft: '42px',
  position: 'relative',
  zIndex: 1,

  [theme.breakpoints.down('laptop')]: {
    paddingLeft: 0,
  },
}));

export const BottomPageHeadlineWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '534px',
  width: '100%',

  p: {
    padding: '0 0 64px 0',
    textAlign: 'start',
    fontSize: '32px',
    lineHeight: '40px',
  },

  [theme.breakpoints.down('tabletM')]: {
    h5: {
      fontSize: '32px',
      lineHeight: '40px',
      marginRight: 0,
      padding: '0 20px',
    },
  },

  [theme.breakpoints.down('mobile')]: {
    h2: {
      textAlign: 'center',
    },

    h5: {
      fontSize: '28px',
      lineHeight: '36px',
      marginRight: 0,
      padding: '0 14px',
    },
  },
}));

export const StyledContainerForm = styled(Box)(({ theme }) => ({
  maxWidth: '886px',
  width: '100%',

  [theme.breakpoints.down('mobile')]: {
    '& .MuiButtonBase-root': {
      marginTop: '14px',
    },
  },
}));

export const BottomMessageImage = styled(Box)(() => ({
  margin: '88px auto 0',
  textAlign: 'center',
}));
