import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import Button from 'src/components/common/Button/Button';
import SoonIcon from 'src/components/Landing/icons/disable-soon-icon.svg';
import AuditLogHistoryIcon from 'src/components/Landing/icons/eCommerceWorkflow/audit-log-history.png';
import SyncIcon from 'src/components/Landing/icons/eCommerceWorkflow/automated-real-time-product-data-sync.png';
import CompletenessIcon from 'src/components/Landing/icons/eCommerceWorkflow/completeness-tracking.png';
import CsvIcon from 'src/components/Landing/icons/eCommerceWorkflow/csv-export-and-import.png';
import MappingIcon from 'src/components/Landing/icons/eCommerceWorkflow/customizable-product-data-mapping.png';
import AssetIcon from 'src/components/Landing/icons/eCommerceWorkflow/digital-asset-management.png';
import BulkActionsIcon from 'src/components/Landing/icons/eCommerceWorkflow/easy-bulk-product-actions.png';
import FiltersIcon from 'src/components/Landing/icons/eCommerceWorkflow/smart-filtering-options.png';
import MediaLibraryIcon from 'src/components/Landing/icons/eCommerceWorkflow/unlimeted-media-library-storage.png';
import IntegrationsIcon from 'src/components/Landing/icons/eCommerceWorkflow/unlimeted-out-of-box-integrations.png';
import { BenefitsContentProps, DeviceSizes } from 'src/components/Landing/types';
import { useWindowWidth } from 'src/hooks/useWindowWidth';

import BenefitsCard from './BenefitsCard';
import { StyledBenefitsBlocks, StyledKeyFeaturesBlock, StyledMobileWrapper, StyledTitle } from './styled';

const START_VISIBLE_PART_OF_LIST = 0;
const LIST_RESTRICTION = 6;
const END_OF_HIDDEN_LIST = 14;

const KeyFeaturesBlock = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  const [isOpenBenefitsList, setIsOpenBenefitsList] = useState<boolean>(false);

  const benefitsList: BenefitsContentProps[] = [
    {
      icon: AssetIcon,
      text: t('landingPage.keyFeatures.benefits.asset'),
      tooltip: t('landingPage.keyFeatures.additions.asset'),
    },
    {
      icon: MediaLibraryIcon,
      text: t('landingPage.keyFeatures.benefits.mediaLibrary'),
      tooltip: t('landingPage.keyFeatures.additions.mediaLibrary'),
    },
    {
      icon: CsvIcon,
      text: t('landingPage.keyFeatures.benefits.csv'),
      tooltip: t('landingPage.keyFeatures.additions.csv'),
    },
    {
      icon: FiltersIcon,
      text: t('landingPage.keyFeatures.benefits.filters'),
      tooltip: t('landingPage.keyFeatures.additions.filters'),
    },
    {
      icon: BulkActionsIcon,
      text: t('landingPage.keyFeatures.benefits.bulkActions'),
      tooltip: t('landingPage.keyFeatures.additions.bulkActions'),
    },
    // TODO: Uncomment when the feature is ready

    // import ApiIcon from 'src/components/Landing/icons/eCommerceWorkflow/api-for-custom-integrations.png';
    // {
    //   icon: ApiIcon,
    //   text: t('landingPage.keyFeatures.benefits.api'),
    //   tooltip: t('landingPage.keyFeatures.additions.api'),
    // },
    {
      icon: MappingIcon,
      text: t('landingPage.keyFeatures.benefits.mappings'),
      tooltip: t('landingPage.keyFeatures.additions.mappings'),
    },
    {
      icon: AuditLogHistoryIcon,
      text: t('landingPage.keyFeatures.benefits.auditLog'),
      tooltip: t('landingPage.keyFeatures.additions.auditLog'),
    },
    {
      icon: IntegrationsIcon,
      text: t('landingPage.keyFeatures.benefits.integrations'),
      tooltip: t('landingPage.keyFeatures.additions.integrations'),
    },
    {
      icon: SyncIcon,
      text: t('landingPage.keyFeatures.benefits.sync'),
      tooltip: t('landingPage.keyFeatures.additions.sync'),
    },
    {
      icon: CompletenessIcon,
      text: t('landingPage.keyFeatures.benefits.completenessTracking'),
      tooltip: t('landingPage.keyFeatures.additions.completenessTracking'),
    },
    {
      // TODO: Remove when the feature is ready
      icon: SoonIcon,
      text: t('landingPage.keyFeatures.benefits.api'),
    },
    {
      icon: SoonIcon,
      text: t('landingPage.keyFeatures.benefits.collaboration'),
    },
    {
      icon: SoonIcon,
      text: t('landingPage.keyFeatures.benefits.variants'),
    },
    {
      icon: SoonIcon,
      text: t('landingPage.keyFeatures.benefits.sharedCatalogs'),
    },
  ];

  const benefitsListBlocks = benefitsList.map((benefitsContent, idx) => (
    <BenefitsCard key={idx} benefitsContent={benefitsContent} />
  ));

  return (
    <StyledKeyFeaturesBlock>
      <StyledTitle variant="h2">{t('landingPage.keyFeatures.title')}</StyledTitle>
      {windowWidth >= DeviceSizes.tablet ? (
        <StyledBenefitsBlocks>{benefitsListBlocks}</StyledBenefitsBlocks>
      ) : (
        <StyledMobileWrapper>
          {benefitsListBlocks.slice(START_VISIBLE_PART_OF_LIST, LIST_RESTRICTION)}
          {isOpenBenefitsList ? (
            <>{benefitsListBlocks.slice(LIST_RESTRICTION, END_OF_HIDDEN_LIST)}</>
          ) : (
            <Box mt="24px">
              <Button onClick={() => setIsOpenBenefitsList(true)}>
                <Typography variant="subtitle1" color="common.white">
                  {t('landingPage.keyFeatures.benefits.showMore')}
                </Typography>
              </Button>
            </Box>
          )}
        </StyledMobileWrapper>
      )}
    </StyledKeyFeaturesBlock>
  );
};

export default KeyFeaturesBlock;
