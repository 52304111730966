import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import RegisterBlock from 'src/components/Forms/RegisterForm/RegisterBlock';
import MessageMini from 'src/components/Landing/icons/message-mini.png';
import Message from 'src/components/Landing/icons/message.png';
import { DeviceSizes } from 'src/components/Landing/types';
import { useWindowWidth } from 'src/hooks/useWindowWidth';

import {
  BottomPageContainer,
  BottomPageImageWrapper,
  BottomPageContentWrapper,
  BottomPageHeadlineWrapper,
  StyledBottomPageBlock,
  StyledContainerForm,
  BottomMessageImage,
} from './styled';

const BottomPageBlock = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  return (
    <StyledBottomPageBlock>
      <BottomPageContainer>
        <BottomPageContentWrapper>
          <BottomPageHeadlineWrapper>
            <Typography>{t('landingPage.bottomPageBlock.headline')}</Typography>
          </BottomPageHeadlineWrapper>
          <StyledContainerForm>
            <RegisterBlock />
          </StyledContainerForm>
        </BottomPageContentWrapper>
        {windowWidth >= DeviceSizes.tablet ? (
          <BottomPageImageWrapper>
            <img width="598px" height="442px" src={Message} loading="lazy" alt="message" />
          </BottomPageImageWrapper>
        ) : (
          <BottomMessageImage>
            <img
              width="353px"
              height="239px"
              src={windowWidth > DeviceSizes.mobileLarge ? Message : MessageMini}
              loading="lazy"
              alt="message"
            />
          </BottomMessageImage>
        )}
      </BottomPageContainer>
    </StyledBottomPageBlock>
  );
};

export default BottomPageBlock;
